import { server } from '@/config/server'
import axios from 'axios'
import Cookies from 'js-cookie'
import { create } from '@/lib/client/customerClient'
import { authCreate } from '@/lib/client/authClient'
import categoryData from '@/lib/bigcommerce/data/category-data.json'
import { retry, hasTimePassed } from '@/lib/helper'

export const client = create()
export const authClient = authCreate()

function fetchWithLogging(url, options) {
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        console.error(`Request failed : ${response}`)
        // throw new Error(`Request failed with status ${response.status}`);
      }
      return response
    })
    .catch((error) => {
      // Log the error
      console.error('Fetch error:', error)
      // throw error; // Rethrow the error to allow downstream error handling
    })
}

export async function getAllProducts(params) {
  const response = await fetchWithLogging(`${server}/api/bigcommerce/products/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params || {}),
  })
  const data = await response.json()
  return data
}

export async function getProductsByCategoryId(categoryId) {
  try {
    const response = await client.post('/category/products', {
      'categories:in': categoryId,
      include: 'primary_image,variants,options',
    })
    return response.data
  } catch (error) {
    console.log(`Error in get product by category id API call`, error)
  }
}

export async function getProductById(productId) {
  try {
    const response = await client.post('/products', {
      product_id: productId,
      include:
        'variants,images,custom_fields,bulk_pricing_rules,primary_image,modifiers,options,videos',
      // include_fields: 'categories',
    })
    if (response?.data?.data) return response.data
    else return null
  } catch (error) {
    console.log(`Error in get product by id API call`, error)
  }
}

export async function getProductByIdForCustomField(productId) {
  try {
    const response = await client.post('/products', {
      product_id: productId,
      include: 'custom_fields',
      include_fields: 'custom_fields',
    })
    if (response?.data?.data) return response.data.data
    else return null
  } catch (error) {
    console.log(`Error in get product by id API call`, error)
  }
}

export async function getProductsBySkus(param) {
  try {
    const response = await client.post('/products/get-by-skus', param)
    return response.data
  } catch (error) {
    console.log(`Error in get product by skus API call`, error)
  }
}

export async function getProductMetafields(productId) {
  try {
    const response = await client.post('/products/metafields', {
      product_id: productId,
    })
    if (response?.data?.data) return response.data
    else return null
  } catch (error) {
    console.log(`Error in get product metafields API call`, error)
  }
}

export async function getProductVariantsMetafields(ids) {
  let endpoints = ids.map((id) => ({
    url: `${server}/api/bigcommerce/products/variant-metafields`,
    request: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_id: id.productId,
        variant_id: id.variantId,
      }),
    },
  }))

  const response = await Promise.all(
    endpoints.map((endpoint) => fetchWithLogging(endpoint.url, endpoint.request))
  ).then((responses) => Promise.all(responses.map((res) => res.json())))

  return response
}

export async function getProductVariantMetafields(productId, variantId) {
  try {
    const response = await client.post('/products/variant-metafields', {
      product_id: productId,
      variant_id: variantId,
    })
    if (response?.data?.data) return response.data
    else return null
  } catch (error) {
    console.log(`Error in get product variant metafields API call`, error)
  }
}

export async function getProductByIdAndParams(productId, params) {
  try {
    const response = await client.post('/products', {
      product_id: productId,
      ...params,
    })
    if (response?.data?.data) return response.data
    else return null
  } catch (error) {
    console.log(`Error in get product by id and params API call`, error)
  }
}

export async function getProductsByIds(productIds) {
  const response = await fetchWithLogging(`${server}/api/bigcommerce/products/get-by-ids`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'id:in': productIds,
    }),
  })
  const data = await response.json()
  return data
}

export async function getProductVariant(product_id, variant_id) {
  try {
    const response = await client.post('/products/product-variant', {
      product_id: product_id,
      variant_id: variant_id,
    })
    return response.data
  } catch (error) {
    console.log(`Error in get product variant API call`, error)
  }
}

export async function getAttributes(params) {
  try {
    const response = await client.get('/getAttributes?' + new URLSearchParams(params))
    return response.data
  } catch (error) {
    console.log(`Error in get Attribute API call`, error)
  }
}

export async function getProductsByAttributeID(attributeIds) {
  try {
    const response = await client.post('/submitAttributes', attributeIds)
    return response.data
  } catch (error) {
    console.log(`Error in get product by Attribute id API call`, error)
  }
}

export async function getProductsByBigCommerceId(params) {
  try {
    const response = await client.post('/products/get-by-ids', params)
    return response.data
  } catch (error) {
    console.log(`Error in get product by big commerce id API call`, error)
  }
}

export async function getReviewRating(productSku) {
  if (!productSku) throw new Error('Yotpo: product id not found')
  const response = await fetchWithLogging(
    `https://api.yotpo.com/products/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/${productSku}/bottomline`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  )

  if (!response) {
    return null
  }

  const data = await response.json()
  return data
}

// get product detail by product url
export async function getProductByName(productId) {
  try {
    const productByHandleRaw = await client.post('/category/products', {
      sku: productId,
      include: 'variants,images,custom_fields,primary_image,modifiers,options',
    })
    return productByHandleRaw
  } catch (error) {
    console.log(`Error in get product by name API call`, error)
  }
}

// get all product path
export async function getProductByUrl(page = 1, allProd = {}) {
  try {
    let allProducts = allProd
    const products = await client.post('/category/products', {
      page: page,
      include_fields: ['custom_url', 'is_visible', 'name'],
    })
    if (page <= products?.data?.meta?.pagination?.total_pages) {
      products?.data &&
        products?.data?.data?.length > 0 &&
        products?.data?.data?.forEach((product) => {
          if (product?.is_visible && product?.custom_url && product?.custom_url?.url) {
            const slug = product?.custom_url?.url

            allProducts = { ...allProducts, [slug]: product }
          }
        })

      return await getProductByUrl(products?.data?.meta?.pagination?.current_page + 1, allProducts)
    }
    return allProducts
  } catch (error) {
    console.log(`Error in get product by Url id API call`, error)
  }
}

// get all categories
export async function getAllCategories(params = {}) {
  try {
    let i = 1
    const limit = 250
    let finalOut = []

    while (1) {
      const response = await axios.post(
        `${
          process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
        }/api/bigcommerce/category/all`,
        {
          page: i,
          limit,
          ...params,
        }
      )

      if (response?.status == 200) {
        if (response?.data?.data?.length > 0) {
          finalOut = finalOut.concat(response?.data?.data)
        } else {
          break
        }
      } else {
        break
      }

      i++
    }

    return finalOut
  } catch (error) {
    console.log('getAllCategories error:', getAllCategories)
    throw error
  }
}

// get all category path
export async function getCategoryByUrlPath(page = 1, allCate = {}) {
  let allCategories = allCate
  const categoriesUrlList = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/api/bigcommerce/category/all`,
    {
      page: page,
      include_fields: ['custom_url', 'parent_id', 'is_visible'],
    }
  )
  if (page <= categoriesUrlList?.data?.meta?.pagination?.total_pages) {
    categoriesUrlList?.data &&
      categoriesUrlList?.data?.data?.length > 0 &&
      categoriesUrlList?.data?.data?.forEach((category) => {
        if (category?.is_visible) {
          if (category.parent_id === 0) {
            const slug = `${category?.custom_url?.url}all/`
            let parentCategory = {
              ...category,
              custom_url: {
                url: `${category.custom_url.url}all/`,
                is_customized: category?.custom_url?.is_customized,
              },
            }
            allCategories = { ...allCategories, [slug]: parentCategory }
          } else {
            const slug = category?.custom_url?.url
            allCategories = { ...allCategories, [slug]: category }
          }
        }
      })

    return await getCategoryByUrlPath(
      categoriesUrlList?.data?.meta?.pagination?.current_page + 1,
      allCategories
    )
  }
  return allCategories
}

// get category path based on category ID
export async function getCategoryUrlById(categoryId, fieldsToInclude) {
  const categoryData = await axios.post(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/api/bigcommerce/category/`,
    {
      category_id: categoryId,
      include_fields: fieldsToInclude ? fieldsToInclude : ['custom_url', 'parent_id'],
    }
  )
  let categoryPath = ''
  if (fieldsToInclude) {
    return categoryData
  } else if (categoryData?.data?.data) {
    if (categoryData?.data?.data?.parent_id === 0) {
      categoryPath = `${categoryData?.data?.data?.custom_url?.url}all/`
    } else {
      categoryPath = categoryData?.data?.data?.custom_url?.url
    }
  }
  return categoryPath
}

export async function getCategoryMetaFields(cateID) {
  try {
    let allCategories = []
    let page = 1

    const { data: categories } = await axios.post(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
      }/api/bigcommerce/category/metafeilds`,
      {
        catId: cateID,
        queryParams: {
          page: page,
          limit: 250,
        },
      }
    )
    if (categories && categories?.data?.length > 0) {
      return categories?.data
    } else {
      return allCategories
    }
  } catch (error) {
    if (error?.response) {
      console.error(
        `get getCategoryMetaFields error - ${error?.response?.status} - ${error?.response?.statusText}`
      )
    } else {
      console.error(`get getCategoryMetaFields error - ${error}`)
    }
  }
}

// get category name by id
export function getCategoryName(categoryId) {
  try {
    // const categoryData = await client.post('/category/', {
    //   category_id: categoryId,
    //   include_fields: ['name'],
    // })
    const category = categoryData.find((cat) => cat.id == categoryId)
    return category
  } catch (error) {
    console.log(`Error in get category name API call`, error)
  }
}

export async function getVariantWarehoseInventory(variantSku) {
  const customerToken = Cookies.get('customer_token')
  if (customerToken) {
    try {
      const result = await authClient.get(`/apiwarehouse/inventory/auth?sku=${variantSku}`)
      return result
    } catch (error) {
      console.log(`Error in get variant warehouse inventory API call`, error)
    }
  }
  const result = await axios.get(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/apiwarehouse/inventory?sku=${variantSku}`
  )
  return result
}

export async function getProductWarehouseInventory(product) {
  const customerToken = Cookies.get('customer_token')

  if (product && product?.variants && product?.variants?.length > 0) {
    let variantSkus = product?.variants
      ?.filter((variant) => !variant.purchasing_disabled)
      .map((variant) => variant.sku)

    if (variantSkus.length > 0) {
      if (customerToken) {
        try {
          const response = await authClient.get(
            `/apiwarehouse/inventory/multiple/auth?sku=${variantSkus.join(',')}`
          )
          if (response?.status == 200 && typeof response?.data == 'object') {
            const { data: inventory } = response?.data

            return inventory
          }
        } catch (error) {
          console.log(`Error in get product warehouse inventory API call`, error)
        }
      }
      const response = await axios.get(
        `${
          process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
        }/apiwarehouse/inventory/multiple?sku=${variantSkus.join(',')}`
      )

      if (response?.status == 200 && typeof response?.data == 'object') {
        const { data: inventory } = response?.data

        return inventory
      }
    }
  }

  return []
}

/* DEPRECATED: use getInventory instead */
export async function getVariantProInventory(variantSku) {
  const customerToken = Cookies.get('customer_token')
  if (customerToken) {
    try {
      const result = await authClient.get(`/apiwarehouse/inventory/auth?sku=${variantSku}`)
      return result
    } catch (error) {
      console.log(`Error in get variant warehouse inventory API call`, error)
    }
  }
  const result = await axios.get(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
    }/apiwarehouse/inventory?sku=${variantSku}`
  )
  return result
}

/* DEPRECATED: use getInventory instead */
export async function getVariantsProInventory(product) {
  const customerToken = Cookies.get('customer_token')

  if (product && product.variants) {
    let variantSkus = product?.variants
      ?.filter((variant) => !variant.purchasing_disabled)
      ?.map((variant) => variant.sku)

    if (variantSkus && variantSkus.length > 0) {
      if (customerToken) {
        try {
          const result = await authClient.get(
            `/apiwarehouse/inventory/multiple/auth?sku=${variantSkus.join(',')}`
          )
          return result
        } catch (error) {
          console.log(`Error in get variant warehouse inventory API call`, error)
        }
      }
      const result = await axios.get(
        `${
          process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
        }/apiwarehouse/inventory/multiple?sku=${variantSkus.join(',')}`
      )
      return result
    }
  }

  return []
}

export async function getVariantsWarehouseInventory(variantSkus) {
  const customerToken = Cookies.get('customer_token')

  if (variantSkus && variantSkus.length > 0) {
    if (customerToken) {
      try {
        const response = await authClient.get(
          `/apiwarehouse/inventory/multiple/auth?sku=${variantSkus.join(',')}`
        )
        if (response?.status == 200 && typeof response?.data == 'object') {
          const { data: inventory } = response?.data

          return inventory
        }
      } catch (error) {
        console.log(`Error in get variant warehouse inventory API call`, error)
      }
    }
    const response = await axios.get(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
      }/apiwarehouse/inventory/multiple?sku=${variantSkus.join(',')}`
    )

    if (response?.status == 200 && typeof response?.data == 'object') {
      const { data: inventory } = response?.data

      return inventory
    }
  }

  return []
}

export const getQuantityOfCartProduct = async (variantSku, cachedInventory) => {
  let cacheTime = 60000

  // if cached inventory is available use it instead of calling the API again
  if (cachedInventory?.inventory && !hasTimePassed(cachedInventory?.lastUpdated, cacheTime)) {
    const variantCachedInventory = cachedInventory?.inventory.find(
      (item) => item.variant_sku === variantSku
    )?.pro_quantity

    return variantCachedInventory || 0
  }

  const response = await getVariantProInventory(variantSku)
  if (response?.status == 200 && typeof response?.data == 'object') {
    const { data: inventory } = response?.data
    if (inventory.find((item) => item.variant_sku === variantSku)?.pro_quantity) {
      return inventory.find((item) => item.variant_sku === variantSku)?.pro_quantity
    } else {
      return 0
    }
  }
}

export const subscribeToOutOfStock = async (data) => {
  try {
    const response = await authClient.post('api/product/notify', data)
    return response
  } catch (error) {
    console.log(`Error in get variant warehouse inventory API call`, error)
    throw error?.response
  }
}

export const getCheckoutPaymentUrl = async (custoemrToken, id) => {
  try {
    const response = await authClient.get(`/api/getcheckoutpaymenturl/${id}`)
    return response.data
  } catch (error) {
    console.log(`Error in checkout payment url API call`, error)
  }
}

/* Function to fetch inventory using either variant sku or parent sku */
export const getInventory = async (skus, isParentSku = false, fetchAuth = false) => {
  if (!skus) {
    return []
  }

  const customerToken = Cookies.get('customer_token') || fetchAuth

  const skuString = skus.join(',')

  const baseUrl = customerToken
    ? '/apiwarehouse/inventory/multiple/auth'
    : '/apiwarehouse/inventory/multiple'

  const url = `${baseUrl}?sku=${skuString}${isParentSku ? '&basedOnSku=true' : ''}`

  const groupByVariantSKU = (data) => {
    const grouped = {}
    for (const item of data) {
      const skuParts = item.variant_sku.split('-')
      const baseSKU = skuParts[0]
      if (!grouped[baseSKU]) {
        grouped[baseSKU] = []
      }
      if (item.variant_sku.startsWith(baseSKU + '-')) {
        grouped[baseSKU].push(item)
      }
    }
    return grouped
  }

  try {
    const response = customerToken
      ? await authClient.get(url)
      : await axios.get(
          `${process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL}${url}`
        )

    if (response?.status == 200 && typeof response?.data == 'object') {
      const { data: inventory } = response?.data

      if (!isParentSku) {
        return inventory
      }

      return groupByVariantSKU(inventory)
    }
  } catch (error) {
    console.log('getInventory error', error)
    return []
  }
}

export const getProductsWithInventoryBySku = async (productsSkus, params) => {
  try {
    if (!productsSkus) {
      return []
    }

    const getProducts = () =>
      getProductsBySkus({ 'sku:in': productsSkus.join(','), ...params, getAllResults: true, trimVariants: true })
    const getInventoryData = () => getInventory(productsSkus, true)

    const [products, inventory] = await Promise.all([retry(getProducts), retry(getInventoryData)])

    const newProducts = products?.data
      ? products?.data
          ?.map((product) => {
            return {
              ...product,
              inventoryData: inventory?.[product.sku] ?? null,
            }
          })
          ?.sort((a, b) => {
            return productsSkus.indexOf(a?.sku) - productsSkus.indexOf(b?.sku)
          })
      : []

    return newProducts
  } catch (error) {
    console.log('getProductsWithInventoryBySku error', error)
    return []
  }
}

export const getProductsWithInventoryById = async (productIds, params) => {
  try {
    if (!productIds) {
      return []
    }

    const groupSize =
      params?.include?.includes('options') || params?.include?.includes('modifiers') ? 10 : 50 // max result data
    const productIdGroups = []

    for (let i = 0; i < productIds.length; i += groupSize) {
      productIdGroups.push(productIds.slice(i, i + groupSize))
    }

    const productPromises = productIdGroups.map((group) =>
      retry(() =>
        getAllProducts({
          ...params,
          'id:in': group.join(','),
        })
      )
    )

    const productResults = await Promise.all(productPromises)

    const allProducts = productResults.flatMap((result) => result?.data ?? [])

    const productSkus = allProducts.map((product) => {
      return product?.sku || product?.variants?.[0]?.sku?.split('-')?.[0] || ''
    })

    const inventory = await getInventory(productSkus, true)

    const inventoryMap = new Map(Object.entries(inventory))

    const newProducts = allProducts
      .map((product) => {
        const sku = product?.sku || product?.variants?.[0]?.sku?.split('-')?.[0] || ''
        return {
          ...product,
          sku: sku,
          inventoryData: inventoryMap.get(sku) ?? null,
        }
      })
      .sort((a, b) => {
        return productIds.indexOf(String(a?.id)) - productIds.indexOf(String(b?.id))
      })

    return newProducts
  } catch (error) {
    console.log('getProductsWithInventoryById error', error)
    return []
  }
}
