import * as types from '@/redux/actionTypes'

export const setProductInventory = (productId, inventory) => async (dispatch) => {
  if (productId && inventory) {
    await dispatch({
      type: types.UPDATE_PRODUCT_INVENTORY,
      payload: {
        productId,
        inventory,
        lastUpdated: Date.now(),
      },
    })
  }
}

export const getProductInventory = () => async (dispatch, getState) => {
  const { productReducer } = getState()

  return productReducer?.productInventory
}

export const setProductList = (page, productList) => async (dispatch, getState) => {
  if (page && productList) {
    const { productReducer } = getState()
    const maxPagesAllowed = 3 // the amount of pages to store in the productList array

    let newProductList = Array.isArray(productReducer.productList)
      ? [...productReducer?.productList]
      : []
    const currentTime = Date.now()

    const pageData = {
      page,
      productList,
      lastUpdated: currentTime,
    }

    const existingPageIndex = newProductList.findIndex((item) => item?.page === page)

    if (existingPageIndex !== -1) {
      newProductList.splice(existingPageIndex, 1)
    } else if (newProductList.length >= maxPagesAllowed) {
      newProductList.shift()
    }

    newProductList.push(pageData)

    await dispatch({
      type: types.UPDATE_PRODUCT_LIST,
      payload: newProductList,
    })
  }
}
